<template>
  <div id="media" class="media">
    <div v-if="loading" class="text-center">
      <v-progress-circular :size="60" color="primary" indeterminate />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
  }),
  created() {
    const ruta =
      this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 4) ? '/professionals/' : '/paciente/';
    this.$router.push(ruta + this.$route.params.id);
  },
};
</script>
